import React from 'react'
import socketIOClient from 'socket.io-client'

export default props => {
  let socket,
    brushSize,
    canvas,
    colors,
    context,
    contextWrap,
    current = {
      color: 'black',
      lineWidth: 1
    },
    drawing = false,
    rect
  const ctxWrap = React.useRef(null)
  const canvasRef = React.useRef(null)
  const ENDPOINT = 'https://whiteboard.ngrok.io'

  const [response, setResponse] = React.useState('')
  // const [drawing, setDrawing] = React.useState(false)
  const [brushColor, setBrushColor] = React.useState('black')
  const [lineWidth, setLineWidth] = React.useState(1)
  const [mousePressed, setMousePressed] = React.useState(false)

  React.useEffect(() => {
    socket = socketIOClient(ENDPOINT)
    socket.on('drawing', onDrawingEvent)

    contextWrap = ctxWrap.current

    canvas = canvasRef.current
    context = canvas.getContext('2d')
    rect = canvas.getBoundingClientRect()
    colors = document.getElementsByClassName('brush-color')
    brushSize = document.getElementsByClassName('brush-size')

    for (var i = 0; i < brushSize.length; i++) {
      brushSize[i].addEventListener('click', onBrushSizeChange, false)
    }

    for (var i = 0; i < colors.length; i++) {
      colors[i].addEventListener('click', onColorUpdate, false)
    }

    addListeners()

    onResize()

    return () => removeListeners()
  }, [])

  function addListeners() {
    canvas.addEventListener('mousedown', onMouseDown, false)
    canvas.addEventListener('mouseup', onMouseUp, false)
    canvas.addEventListener('mouseout', onMouseUp, false)
    canvas.addEventListener('mousemove', throttle(onMouseMove, 10), false)

    //Touch support for mobile devices
    canvas.addEventListener('touchstart', onMouseDown, false)
    canvas.addEventListener('touchend', onMouseUp, false)
    canvas.addEventListener('touchcancel', onMouseUp, false)
    canvas.addEventListener('touchmove', throttle(onMouseMove, 10), false)
  }

  function removeListeners() {
    canvas.removeEventListener('mousedown', onMouseDown)
    canvas.removeEventListener('mouseup', onMouseUp)
    canvas.removeEventListener('mouseout', onMouseUp)
    canvas.removeEventListener('mousemove', onMouseMove)
  }

  const generateStrokes = () => {
    let strokes = []

    for (var i = 1; i <= 8; i++) {
      strokes.push(
        <div
          data-strokesize={i}
          className={`brush-size stroke-${i} ${
            i === lineWidth ? 'active' : ''
          }`}
          key={i}
        />
      )
    }

    return strokes
  }

  const setBrushRadius = (e, i) => {
    setLineWidth(e.target.dataset.strokesize)

    // remove the arrow from the old stroke
    let children = Array.from(document.querySelector(`.strokes`).children)
    children.forEach(function(elem) {
      elem.classList.remove('active')
    })

    // add active state to the pressed stroke
    document
      .querySelector(`.brush-size.stroke-${e.target.dataset.strokesize}`)
      .classList.add('active')
  }

  function onDrawingEvent(data) {
    var w = contextWrap.clientWidth
    var h = contextWrap.clientHeight
    drawLine(data.x0 * w, data.y0 * h, data.x1 * w, data.y1 * h, data.color)
  }

  function drawLine(x0, y0, x1, y1, color, emit) {
    context.beginPath()
    context.moveTo(x0, y0)
    context.lineTo(x1, y1)
    context.strokeStyle = color
    context.lineWidth = current.lineWidth
    context.stroke()
    context.closePath()

    if (!emit) {
      return
    }

    var w = contextWrap.clientWidth
    var h = contextWrap.clientHeight

    socket.emit('drawing', {
      x0: x0 / w,
      y0: y0 / h,
      x1: x1 / w,
      y1: y1 / h,
      color: color
    })
  }

  function onMouseDown(e, color) {
    addListeners()
    drawing = true
    current.x = e.clientX - rect.left || e.touches[0].clientX
    current.y = e.clientY - rect.top || e.touches[0].clientY
  }

  // make the canvas fill its parent
  function onResize() {
    canvas.height = contextWrap.clientHeight
    canvas.width = contextWrap.clientWidth
  }

  function onMouseUp(e) {
    if (!drawing) {
      return
    }
    drawLine(
      current.x,
      current.y,
      e.clientX - rect.left || e.touches[0].clientX,
      e.clientY - rect.top || e.touches[0].clientY,
      current.color,
      true
    )
    drawing = false
  }

  function onMouseMove(e) {
    if (!drawing) {
      return
    }
    drawLine(
      current.x,
      current.y,
      e.clientX - rect.left || e.touches[0].clientX,
      e.clientY - rect.top || e.touches[0].clientY,
      current.color,
      true
    )
    current.x = e.clientX - rect.left || e.touches[0].clientX
    current.y = e.clientY - rect.top || e.touches[0].clientY
  }

  function throttle(callback, delay) {
    var previousCall = new Date().getTime()
    return function() {
      var time = new Date().getTime()

      if (time - previousCall >= delay) {
        previousCall = time
        callback.apply(null, arguments)
      }
    }
  }

  function clearCanvas() {
    contextWrap = ctxWrap.current
    canvas = canvasRef.current
    context = canvas.getContext('2d')
    rect = canvas.getBoundingClientRect()
    colors = document.getElementsByClassName('brush-color')
    brushSize = document.getElementsByClassName('brush-size')

    context.clearRect(
      0,
      0,
      (canvas.height = contextWrap.clientHeight),
      (canvas.width = contextWrap.clientWidth)
    )
  }

  function changeBrushColor(color) {
    setBrushColor(color)
    current.color = color
  }

  function onBrushSizeChange(e) {
    setBrushRadius(e)
    current.lineWidth = e.target.dataset.strokesize
  }

  function onColorUpdate(e) {
    setBrushColor(e.target.dataset.color)
    current.color = e.target.dataset.color
  }

  return (
    <>
      <div className="paintbrush--inner__content--left">
        <div className="paintbrush--inner__content--left__icons">
          <div className="icon">
            <img
              alt="eraser icon"
              src={require('../assets/img/eraser.png')}
              onClick={clearCanvas}
              title="Clear drawing"
            />
          </div>
          <div className="icon">
            <img
              src={require('../assets/img/free-line.png')}
              alt="free drawing icons"
            />
          </div>
        </div>
        <div className="paintbrush--inner__content--left__stroke-size">
          <div className="strokes">{generateStrokes()}</div>
        </div>
      </div>
      <div className="paintbrush--inner__content--right">
        <div
          className="paintbrush--inner__content--right__canvas"
          ref={ctxWrap}
        >
          <canvas className="whiteboard" ref={canvasRef} />
        </div>
        <div className="paintbrush--inner__content--right__colors">
          <div className="current-color" style={{ background: brushColor }} />
          <div className="brushes">
            <div
              className="brush-color"
              data-color="white"
              style={{ background: 'white' }}
            />
            <div
              className="brush-color"
              data-color="silver"
              style={{ background: 'silver' }}
            />
            <div
              className="brush-color"
              data-color="red"
              style={{ background: 'red' }}
            />
            <div
              className="brush-color"
              data-color="yellow"
              style={{ background: 'yellow' }}
            />
            <div
              className="brush-color"
              data-color="lime"
              style={{ background: 'lime' }}
            />
            <div
              className="brush-color"
              data-color="aqua"
              style={{ background: 'aqua' }}
            />
            <div
              className="brush-color"
              data-color="blue"
              style={{ background: 'blue' }}
            />
            <div
              className="brush-color"
              data-color="fuchsia"
              style={{ background: 'fuchsia' }}
            />
            <div
              className="brush-color"
              data-color="khaki"
              style={{ background: 'khaki' }}
            />
            <div
              className="brush-color"
              data-color="black"
              style={{ background: 'black' }}
            />
            <div
              className="brush-color"
              data-color="lightslategray"
              style={{ background: 'lightslategray' }}
            />
            <div
              className="brush-color"
              data-color="crimson"
              style={{ background: 'crimson' }}
            />
            <div
              className="brush-color"
              data-color="darkkhaki"
              style={{ background: 'darkkhaki' }}
            />
            <div
              className="brush-color"
              data-color="seagreen"
              style={{ background: 'seagreen' }}
            />
            <div
              className="brush-color"
              data-color="cadetblue"
              style={{ background: 'catedblue' }}
            />
            <div
              className="brush-color"
              data-color="slateblue"
              style={{ background: 'slateblue' }}
            />
            <div
              className="brush-color"
              data-color="mediumorchid"
              style={{ background: 'mediumorchid' }}
            />
            <div
              className="brush-color"
              data-color="mediumspringgreen"
              style={{ background: 'mediumspringgreen' }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
