import React from 'react'

export default function AboutMe() {
  return (
    <div className="terminal-content container card">
      <span className="uiterminal-line is-empty is-size-2 uibg-black section--title">
        <h1>A little about me</h1>
      </span>
      <div className="personal-data--info">
        <u>
          <h4>Personal data</h4>
        </u>
        <p>Name: Lucho Su&aacute;rez</p>
        <p>
          E-mail: <a href="mailto:im@lucho.dev">im@lucho.dev</a>
        </p>
        <p>Twitter: @luchoster</p>
        <p>Github: @luchoster</p>
      </div>
      <div className="personal-data--education">
        <u>
          <h4>Education</h4>
        </u>
        <p>
          USAC (Guatemala)
          <br />
          UMG (Guatemala)
          <br />
          PreU (HS – Guatemala)
        </p>
      </div>
      <div className="personal-data--motto">
        <span className="uiterminal-line is-empty is-size-2 is-centered animated fadeIn">
          <h2 className="slogan animate fadeInBigUp uitext-success uibg-black">
            " I would like to change the world, but they won't give me the
            source code. "
          </h2>
          <span className="uiterminal-line is-empty uibg-black" />
        </span>
      </div>
    </div>
  )
}
