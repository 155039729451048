import React from 'react'
import { motion } from 'framer-motion'

export default props => {
  const titleBar = React.useRef(null)
  const iconsContainer = React.useRef(null)
  const windowBorder = React.useRef(null)

  const [contextMenu, setContextMenu] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [fullScreen, setFullScreen] = React.useState(false)
  const [startDrag, setStartDrag] = React.useState(false)

  React.useEffect(() => {
    props.constraintsRef.current.clientWidth >= 768 &&
      titleBar.current.addEventListener('mouseenter', e => {
        setStartDrag(true)
      })

    iconsContainer.current.addEventListener('mouseenter', e => {
      _stopDrag()
    })

    // making the context menu work
    if (contextMenu) {
      typeof document !== 'undefined' &&
        document.addEventListener('keydown', function(event) {
          if (event.code === 'KeyC') {
            props.show(false)
          }
          if (event.code === 'KeyM') {
            _startDrag()
          }
        })
    }
  })

  function _startDrag() {
    setDragging(true)
    setStartDrag(true)
    setContextMenu(false)
  }

  function _stopDrag() {
    setDragging(false)
    setStartDrag(false)
  }

  return (
    <motion.div
      className={`window-border ${props.id} ${
        dragging ? 'ui-draggable-dragging' : ''
      }
      ${props.fullScreen ? 'maximize' : 'open'}`}
      drag={
        !props.fullScreen && props.constraintsRef.current.clientWidth >= 768
          ? startDrag
          : false
      }
      dragConstraints={props.constraintsRef}
      dragMomentum={false}
      onDragStart={_startDrag}
      onDragEnd={_stopDrag}
      ref={windowBorder}
    >
      <div className="title" onMouseUp={_stopDrag} ref={titleBar}>
        <div
          id="window"
          className={`button close ${
            contextMenu ? 'context-menu--active' : 'context-menu--disabled'
          }`}
          onClick={() => setContextMenu(!contextMenu)}
          onDoubleClick={() => props.show(false)}
        >
          &#8212;
        </div>
        {props.windowTitle} {props.subTitleText && ` - ${props.subTitleText}`}
        {!props.fullScreen && (
          <div className="button" id="max" onClick={() => setFullScreen(true)}>
            <i className="caret-up" />
          </div>
        )}
        <div className="button" id="min" onClick={() => props.show(false)}>
          <i className="caret-down" />
        </div>
      </div>
      <div className={`window ${props.id}`}>
        {props.menubar && (
          <div className="menubar">
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>F</span>ile
            </div>
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>O</span>ptions
            </div>
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>W</span>indows
            </div>
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>H</span>elp
            </div>
          </div>
        )}
        <div ref={iconsContainer} className={`${props.id}`}>
          {props.active && props.children}
        </div>
      </div>
      <div
        className={`context-menu ${
          contextMenu ? 'context-menu--active' : 'context-menu--disabled'
        }`}
      >
        {!props.fullScreen && (
          <div
            className="context-item"
            onClick={() => !fullScreen && _startDrag()}
          >
            <span style={{ textDecoration: 'underline' }}>M</span>ove
          </div>
        )}
        <div className="context-item" onClick={() => props.show(false)}>
          Mi<span style={{ textDecoration: 'underline' }}>n</span>imize
        </div>
        {!props.fullScreen && (
          <div className="context-item">
            Ma<span style={{ textDecoration: 'underline' }}>x</span>imize
          </div>
        )}
        <div className="context-item" onClick={() => props.show(false)}>
          <span style={{ textDecoration: 'underline' }}>C</span>lose
        </div>
      </div>
    </motion.div>
  )
}
