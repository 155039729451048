import React from 'react'
import { motion } from 'framer-motion'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

import AlertOk from '../components/AlertOk'
import ContactForm from '../components/ContactForm'
import ExperienceFrame from '../components/ExperienceFrame'
import FlyingWindows from '../components/FlyingWindows'
import NasaAPod from '../components/NasaAPOD'
import ProgramManager from '../components/ProgramManager'
import PaintBrush from '../components/PaintBrush'
import PaintBrushCanvas from '../components/PaintBrushCanvas'
import Terminal from '../components/Terminal'
import WindowClock from '../components/WindowClock'
import WindowFrame from '../components/WindowFrame'
import WindowDesktopSettings from '../components/WindowDesktopSettings'

import SplashImg from '../assets/img/splash.jpg'
import DesktopBg from '../assets/img/bgs/w31-colorful.webp'

export default props => {
  const constraintsRef = React.useRef()

  const [showAlert, setShowAlert] = React.useState(false)
  const [showProgramManager, setShowProgramManager] = React.useState(false)
  const [showTerminal, setShowTerminal] = React.useState(false)
  const [showExperience, setShowExperience] = React.useState(false)
  const [showFlyingWindows, setShowFlyingWindows] = React.useState(false)
  const [showDesktopSettings, setShowDesktopSettings] = React.useState(false)
  const [showPaintBrush, setShowPaintBrush] = React.useState(false)
  const [showNasaAPOD, setNasaAPOD] = React.useState(false)
  const [showContactForm, setShowContactForm] = React.useState(false)
  const [showClock, setShowClock] = React.useState(false)
  const [showSplash, setShowSplash] = React.useState(true)

  const { data } = props
  const { edges: experienceData } = data.ExperienceQuery

  React.useEffect(() => {
    showSplash &&
      setTimeout(() => {
        setShowSplash(false)
        document.body.style.backgroundColor = '#808080'
        document.body.style.backgroundImage = `url(${DesktopBg})`
        setShowAlert(true)
      }, 1500)
  }, [showSplash])

  function formatDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
    return [month, day, year].join('/')
  }

  return (
    <Layout>
      <SEO title="Lucho Suarez" />
      {showSplash ? (
        <div
          className={`splash-screen animated ${showSplash ? '' : 'fadeOut'}`}
        >
          <img
            src={SplashImg}
            alt="S logo and Lucho Suarez with copyright info"
          />
        </div>
      ) : (
        <div className="desktop-background">
          <div className="desktop animated fadeIn" ref={constraintsRef}>
            {showProgramManager ? (
              <ProgramManager
                constraintsRef={constraintsRef}
                show={setShowProgramManager}
                showDesktopSettings={setShowDesktopSettings}
                showClock={setShowClock}
                showExperience={setShowExperience}
                showFlyingWindows={setShowFlyingWindows}
                showPaintBrush={setShowPaintBrush}
                showTerminal={setShowTerminal}
                showNasaAPOD={setNasaAPOD}
                showContactForm={setShowContactForm}
              />
            ) : null}
            {showAlert && (
              <AlertOk
                active={showAlert}
                constraintsRef={constraintsRef}
                windowTitle="Friendly Reminder"
                id="alert" // required
                show={setShowAlert}
                fullScreen={false}
                menubar={false}
                iconName="arrow-down" // required
                alertContent="Don't forget to double click to open the applications."
              />
            )}
            {showTerminal ? (
              <Terminal
                constraintsRef={constraintsRef}
                show={setShowTerminal}
              />
            ) : null}
            {showClock && (
              <WindowClock
                active={showClock}
                constraintsRef={constraintsRef}
                windowTitle="Clock"
                id="clock" // required
                show={setShowClock}
                date={formatDate()}
                subTitle={true}
              />
            )}
            {showFlyingWindows && (
              <WindowFrame
                active={showFlyingWindows}
                constraintsRef={constraintsRef}
                windowTitle="Flying Windows"
                id="windows-screensaver" // required
                show={setShowFlyingWindows}
                subTitleText="Screensaver"
                subTitle={true}
                fullScreen
                menubar={false}
              >
                <FlyingWindows id="windows-screensaver" />
              </WindowFrame>
            )}
            {showDesktopSettings && (
              <WindowDesktopSettings
                active={showDesktopSettings}
                constraintsRef={constraintsRef}
                windowTitle="Desktop"
                id="windows-desktop-settings" // required
                show={setShowDesktopSettings}
                fullScreen={false}
                menubar={false}
              />
            )}
            {showPaintBrush && (
              <PaintBrush
                active={showPaintBrush}
                constraintsRef={constraintsRef}
                windowTitle="Paintbrush"
                subTitleText="[Untitled]"
                id="paintbrush" // required
                show={setShowPaintBrush}
                menubar
                fullScreen={false}
              >
                <PaintBrushCanvas />
              </PaintBrush>
            )}
            {showExperience && (
              <ExperienceFrame
                active={showExperience}
                constraintsRef={constraintsRef}
                windowTitle="Experience"
                subTitleText="History"
                id="experience-sheet" // required
                show={setShowExperience}
                menubar={false}
                data={experienceData}
              />
            )}
            {showNasaAPOD && (
              <NasaAPod
                active={showExperience}
                constraintsRef={constraintsRef}
                windowTitle="Nasa"
                subTitleText="Astronomy Picture of the Day"
                id="nasa-apod" // required
                show={setNasaAPOD}
                menubar={false}
              />
            )}
            {showContactForm && (
              <ContactForm
                active={showContactForm}
                constraintsRef={constraintsRef}
                windowTitle="Don't be shy"
                subTitleText="Send me a Message"
                id="contactForm" // required
                show={setShowContactForm}
                menubar={false}
              />
            )}
            <div className="icons">
              {!showProgramManager ? (
                <motion.div
                  className="icon white animated heartBeat mainDesktopIcon"
                  drag
                  dragConstraints={constraintsRef}
                  dragPropagation
                  onDoubleClick={() => setShowProgramManager(true)}
                  role="presentation"
                >
                  <motion.img
                    src={require('../assets/img/root.png')}
                    alt="desktop icon for the program manager"
                  />
                  <motion.div className="text">Program Manager</motion.div>
                </motion.div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export const dataQueries = graphql`
  {
    ExperienceQuery: allSanityExperience {
      edges {
        node {
          id
          title
          years
          sort
          _rawBody
        }
      }
    }
    WorkQuery: allSanityProject {
      edges {
        node {
          id
          title
          sort
          mainImage {
            _key
            _type
            asset {
              url
              fixed(width: 150, height: 150) {
                ...GatsbySanityImageFixed
              }
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`
