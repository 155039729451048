import React from 'react'
import { motion } from 'framer-motion'

export default props => {
  const titleBar = React.useRef(null)
  const iconsContainer = React.useRef(null)
  const windowBorder = React.useRef(null)

  const [contextMenu, setContextMenu] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [fullScreen, setFullScreen] = React.useState(props.fullScreen)
  const [startDrag, setStartDrag] = React.useState(false)

  React.useEffect(() => {
    titleBar.current.addEventListener('mouseenter', e => {
      setStartDrag(true)
    })

    iconsContainer.current.addEventListener('mouseenter', e => {
      _stopDrag()
    })

    // making the context menu work
    if (contextMenu) {
      typeof document !== 'undefined' &&
        document.addEventListener('keydown', function(event) {
          if (event.code === 'KeyC') {
            props.show(false)
          }
          if (event.code === 'KeyM') {
            _startDrag()
          }
        })
    }

    document.addEventListener('keydown', _recognizeKey, false)

    return () => document.removeEventListener('keydown', _recognizeKey, false)
  })

  function _recognizeKey(event) {
    if (event.code === 'Enter') {
      props.show(false)
    }
  }

  function _startDrag() {
    setDragging(true)
    setStartDrag(true)
    setContextMenu(false)
  }

  function _stopDrag() {
    setDragging(false)
    setStartDrag(false)
  }

  return (
    <motion.div
      className={`window-border ${props.id} ${
        dragging ? 'ui-draggable-dragging' : ''
      }`}
      drag={startDrag}
      dragConstraints={props.constraintsRef}
      dragMomentum={false}
      onDragStart={_startDrag}
      onDragEnd={_stopDrag}
      ref={windowBorder}
    >
      <div className="title" onMouseUp={_stopDrag} ref={titleBar}>
        <div
          id="window"
          className={`button close ${
            contextMenu ? 'context-menu--active' : 'context-menu--disabled'
          }`}
          onClick={() => setContextMenu(!contextMenu)}
          onDoubleClick={() => props.show(false)}
        >
          -
        </div>
        {props.windowTitle} {props.subTitle && ` - ${props.subTitleText}`}
      </div>
      <div className={`window`}>
        <div ref={iconsContainer} className={`${props.id}__inner`}>
          <div className="alert-content">
            <i className={`alert-content__icon info-white ${props.iconName}`} />
            <p>{props.alertContent}</p>
          </div>
          <button className="button" onClick={() => props.show(false)}>
            OK
          </button>
        </div>
      </div>
      <div
        className={`context-menu ${
          contextMenu ? 'context-menu--active' : 'context-menu--disabled'
        }`}
      >
        <div className="context-item" onClick={() => _startDrag()}>
          <span style={{ textDecoration: 'underline' }}>M</span>ove
        </div>
      </div>
    </motion.div>
  )
}
