import React from 'react'
import { navigate } from 'gatsby'
import { motion } from 'framer-motion'

export default props => {
  const titleBar = React.useRef(null)
  const iconsContainer = React.useRef(null)
  const windowBorder = React.useRef(null)

  const [contextMenu, setContextMenu] = React.useState(false)
  const [fullScreen, setFullScreen] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [startDrag, setStartDrag] = React.useState(false)

  React.useEffect(() => {
    props.constraintsRef.current.clientWidth >= 768 &&
      titleBar.current.addEventListener('mouseenter', e => {
        setStartDrag(true)
      })

    iconsContainer.current.addEventListener('mouseenter', e => {
      _stopDrag()
    })

    if (contextMenu) {
      typeof document !== 'undefined' &&
        document.addEventListener('keydown', _recognizeKey, false)
    }

    return () => document.removeEventListener('keydown', _recognizeKey, false)
  })

  function _recognizeKey(event) {
    if (event.code === 'KeyC') {
      props.show(false)
    }
    if (event.code === 'KeyM') {
      _startDrag()
    }
  }

  function _startDrag() {
    setDragging(true)
    setStartDrag(true)
    setContextMenu(false)
  }

  function _stopDrag() {
    setDragging(false)
    setStartDrag(false)
  }

  return (
    <motion.div
      className={`window-border program-manager ${
        dragging ? 'ui-draggable-dragging' : ''
      } ${fullScreen ? 'maximize' : 'open'}`}
      drag={
        !fullScreen && props.constraintsRef.current.clientWidth >= 768
          ? startDrag
          : false
      }
      dragConstraints={props.constraintsRef}
      dragMomentum={false}
      onDragStart={_startDrag}
      onDragEnd={_stopDrag}
      ref={windowBorder}
    >
      <div
        className="title"
        onDoubleClick={() => (fullScreen ? setFullScreen(false) : {})}
        onMouseUp={_stopDrag}
        ref={titleBar}
      >
        <div
          id="window"
          className={`button close ${
            contextMenu ? 'context-menu--active' : 'context-menu--disabled'
          }`}
          onClick={() => setContextMenu(!contextMenu)}
          onDoubleClick={() => props.show(false)}
        >
          &#8212;
        </div>
        Program Manager
        <div className="button" id="max" onClick={() => setFullScreen(true)}>
          <i className="caret-up" />
        </div>
        <div
          className="button"
          id="min"
          onClick={() =>
            fullScreen ? setFullScreen(false) : props.show(false)
          }
        >
          <i className="caret-down" />
        </div>
      </div>
      <div className="window">
        {
          // <div className="menubar">
          //   <div className="menuitem">
          //     <span style={{ textDecoration: 'underline' }}>F</span>ile
          //   </div>
          //   <div className="menuitem">
          //     <span style={{ textDecoration: 'underline' }}>O</span>ptions
          //   </div>
          //   <div className="menuitem">
          //     <span style={{ textDecoration: 'underline' }}>W</span>indows
          //   </div>
          //   <div className="menuitem">
          //     <span style={{ textDecoration: 'underline' }}>H</span>elp
          //   </div>
          // </div>
        }
        <div
          className="icons"
          ref={iconsContainer}
          onClick={() => setContextMenu(false)}
        >
          <motion.div
            className="icon"
            layout
            drag
            dragConstraints={iconsContainer}
            onDoubleClick={() => props.showTerminal(true)}
          >
            <motion.img
              src={require('../assets/img/terminal.png')}
              alt="terminal icon"
            />
            <div className="text">Terminal</div>
          </motion.div>
          <motion.div
            className="icon"
            drag
            dragConstraints={iconsContainer}
            onDoubleClick={() => props.showExperience(true)}
          >
            <motion.img
              src={require('../assets/img/swiss-army-knife.png')}
              alt="a swiss army knife icon"
            />
            <div className="text">Experience</div>
          </motion.div>
          <motion.div
            className="icon"
            drag
            dragConstraints={iconsContainer}
            onDoubleClick={() => navigate('/bsod/')}
          >
            <motion.img
              src={require('../assets/img/folder.png')}
              alt="folder icon"
            />
            <div className="text">Games</div>
          </motion.div>
          <motion.div
            className="icon"
            drag
            dragConstraints={iconsContainer}
            onDoubleClick={() => props.showClock(true)}
          >
            <motion.img
              src={require('../assets/img/clock.png')}
              alt="clock icon"
            />
            <div className="text">Clock</div>
          </motion.div>
          <motion.div
            className="icon"
            drag
            dragConstraints={iconsContainer}
            onDoubleClick={() => props.showFlyingWindows(true)}
          >
            <motion.img
              src={require('../assets/img/win-logo.png')}
              alt="windows logo icon to show Screensaver"
            />
            <div className="text">Screensaver</div>
          </motion.div>
          <motion.div
            className="icon"
            drag
            dragConstraints={iconsContainer}
            onDoubleClick={() => props.showDesktopSettings(true)}
          >
            <motion.img
              src={require('../assets/img/desktop.png')}
              alt="desktop icon"
            />
            <div className="text">Desktop</div>
          </motion.div>
          <motion.div
            className="icon"
            drag
            dragConstraints={iconsContainer}
            onDoubleClick={() => props.showPaintBrush(true)}
          >
            <motion.img
              src={require('../assets/img/paintbrush.png')}
              alt="desktop icon"
            />
            <div className="text">Paintbrush</div>
          </motion.div>
          <motion.div
            className="icon"
            drag
            dragConstraints={iconsContainer}
            onDoubleClick={() =>
              window.open('https://b64.lucho.dev/', '_blank')
            }
          >
            <motion.img
              src={require('../assets/img/b64_logo.webp')}
              alt="desktop icon"
            />
            <div className="text">B64 Encoder</div>
          </motion.div>
          <motion.div
            className="icon"
            drag
            dragConstraints={iconsContainer}
            onDoubleClick={() =>
              window.open('https://pgp.lucho.dev/', '_blank')
            }
          >
            <motion.img
              src={require('../assets/img/pgp_logo.webp')}
              alt="desktop icon"
            />
            <div className="text">PGP Translator</div>
          </motion.div>
          <motion.div
            className="icon"
            drag
            dragConstraints={iconsContainer}
            onDoubleClick={() => props.showContactForm(true)}
          >
            <motion.img
              src={require('../assets/img/mail.png')}
              alt="desktop icon"
            />
            <div className="text">Contact Me</div>
          </motion.div>
        </div>
      </div>
      <div className="bottomright_handle" />
      <div
        className={`context-menu ${
          contextMenu ? 'context-menu--active' : 'context-menu--disabled'
        }`}
      >
        <div className="context-item" onClick={() => _startDrag()}>
          <span style={{ textDecoration: 'underline' }}>M</span>ove
        </div>
        <div className="context-item" onClick={() => props.show(false)}>
          Mi<span style={{ textDecoration: 'underline' }}>n</span>imize
        </div>
        <div className="context-item" onClick={() => setFullScreen(true)}>
          Ma<span style={{ textDecoration: 'underline' }}>x</span>imize
        </div>
        <div className="context-item" onClick={() => props.show(false)}>
          <span style={{ textDecoration: 'underline' }}>C</span>lose
        </div>
      </div>
    </motion.div>
  )
}
