import React from 'react'

export default props => {
  const hourHand = React.useRef(null)
  const minuteHand = React.useRef(null)
  const secondHand = React.useRef(null)

  const [digitalTime, setDigitalTime] = React.useState()

  let time = new Date()

  React.useEffect(() => {
    props.analog ? analogClock() : digitalClock()

    return () => clearInterval(timer())
  })

  /**
   *  Generating the second marks
   */
  function generateSecs() {
    let secs = []

    for (var i = 1; i <= 60; i++) {
      secs.push(<div className="sec" key={i} />)
    }

    return secs
  }

  /**
   * Timer of the clock
   */
  function timer() {
    sethandRotation('hour')
    sethandRotation('minute')
    sethandRotation('second')
  }

  /**
   * Changes the rotation of the hands of the clock
   * @param  {HTMLElement} hand   One of the hand of the clock
   * @param  {number}      degree degree of rotation of the hand
   */
  function sethandRotation(hand) {
    let date = new Date(),
      hours,
      minutes,
      seconds,
      percentage,
      degree

    switch (hand) {
      case 'hour':
        hours = date.getHours()
        hand = hourHand
        percentage = numberToPercentage(hours, 12)
        break
      case 'minute':
        minutes = date.getMinutes()
        hand = minuteHand
        percentage = numberToPercentage(minutes, 60)
        break
      case 'second':
        seconds = date.getSeconds()
        hand = secondHand
        percentage = numberToPercentage(seconds, 60)
        break
      default:
    }

    degree = percentageToDegree(percentage)
    if (hand.current) {
      hand.current.style.transform = `rotate(${degree}deg) translate(-50%, -50%)`
    }
  }

  /**
   * Converting a number to a percentage
   * @param  {number} number Number
   * @param  {number} max    Maximum value of the number
   * @return {number}        Return a percentage
   */
  function numberToPercentage(number = 0, max = 60) {
    return (number / max) * 100
  }

  /**
   * Converting a percentage to a degree
   * @param  {number} percentage Percentage
   * @return {number}            Return a degree
   */
  function percentageToDegree(percentage = 0) {
    return (percentage * 360) / 100
  }

  /**
   *  getting the analog clock functions
   */
  function analogClock() {
    generateSecs()
    hourHand.current && setInterval(() => timer(), 1000)
  }

  /**
   *  getting the digital clock
   */
  function digitalClock() {
    let addZero = n => (n < 10 ? '0' + n : n)

    setInterval(() => {
      let d, h, m, s, t

      d = new Date()
      h = addZero(d.getHours())
      m = addZero(d.getMinutes())
      s = addZero(d.getSeconds())
      t = `${h}:${m}:${s}`

      setDigitalTime(t)
    }, 1000)
  }

  return props.analog ? (
    <div className="clock__container">
      <div className="inner">
        <div className="hour hand" ref={hourHand} />
        <div className="minute hand" ref={minuteHand} />
        <div className="seconds hand" ref={secondHand} />
        <div className="secs">{generateSecs()}</div>
      </div>
    </div>
  ) : (
    <div className="clock__container">
      <div className="clock__container__digital">
        <p className="clock__container__digital__time">{digitalTime}</p>
        <p className="clock__container__digital__date">{props.date}</p>
      </div>
    </div>
  )
}
