import React from 'react'
import { motion } from 'framer-motion'

export default props => {
  const titleBar = React.useRef(null)
  const iconsContainer = React.useRef(null)
  const windowBorder = React.useRef(null)
  const wallpaperList = React.useRef(null)

  const [contextMenu, setContextMenu] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [fullScreen, setFullScreen] = React.useState(false)
  const [showWallpaperList, setShowWallpaperList] = React.useState(false)
  const [startDrag, setStartDrag] = React.useState(false)
  const [currentBg, setCurrentBg] = React.useState('')

  React.useEffect(() => {
    wallpaperList.current.style.display = showWallpaperList ? 'block' : 'none'

    props.constraintsRef.current.clientWidth >= 768 &&
      titleBar.current.addEventListener('mouseenter', e => {
        setStartDrag(true)
      })

    iconsContainer.current.addEventListener('mouseenter', e => {
      _stopDrag()
    })

    // making the context menu work
    if (contextMenu) {
      typeof document !== 'undefined' &&
        document.addEventListener('keydown', function(event) {
          if (event.code === 'KeyC') {
            props.show(false)
          }
          if (event.code === 'KeyM') {
            _startDrag()
          }
        })
    }
  })

  React.useEffect(
    () => {
      setShowWallpaperList(false)
      if (currentBg !== '') {
        document.body.style.background =
          'url(' + require(`../assets/img/bgs/${currentBg}.jpg`) + ')'
      }
    },
    [currentBg]
  )

  function _startDrag() {
    setDragging(true)
    setStartDrag(true)
    setContextMenu(false)
  }

  function _stopDrag() {
    setDragging(false)
    setStartDrag(false)
  }

  return (
    <motion.div
      className={`window-border ${dragging ? 'ui-draggable-dragging' : ''}
      ${props.fullScreen ? 'maximize' : 'open'}`}
      drag={
        !props.fullScreen && props.constraintsRef.current.clientWidth >= 768
          ? startDrag
          : false
      }
      dragConstraints={props.constraintsRef}
      dragMomentum={false}
      onDragStart={_startDrag}
      onDragEnd={_stopDrag}
      ref={windowBorder}
    >
      <div className="title" onMouseUp={_stopDrag} ref={titleBar}>
        <div
          id="window"
          className={`button close ${
            contextMenu ? 'context-menu--active' : 'context-menu--disabled'
          }`}
          onClick={() => setContextMenu(!contextMenu)}
          onDoubleClick={() => props.show(false)}
        >
          -
        </div>
        {props.windowTitle} {props.subTitle && ` - ${props.subTitleText}`}
        <div className="button" id="min" onClick={() => props.show(false)}>
          <i className="caret-down" />
        </div>
      </div>
      <div className={`window ${props.id}`}>
        {props.menubar && (
          <div className="menubar">
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>F</span>ile
            </div>
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>O</span>ptions
            </div>
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>W</span>indows
            </div>
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>H</span>elp
            </div>
          </div>
        )}
        <div ref={iconsContainer} className={`${props.id}__inner`}>
          <div className="split-half">
            <fieldset>
              <legend>Wallpaper</legend>
              <div className="wallpaper-list">
                <div className="wallpaper-list__dropdown">
                  <input value={currentBg || 'No File'} disabled />
                  <div
                    className="wallpaper-list__dropdown__list"
                    ref={wallpaperList}
                  >
                    <p onClick={() => setCurrentBg('arcade')}>Arcade</p>
                    <p onClick={() => setCurrentBg('argyle')}>Argyle</p>
                    <p onClick={() => setCurrentBg('castle')}>Castle</p>
                    <p onClick={() => setCurrentBg('cars')}>Cars</p>
                    <p onClick={() => setCurrentBg('egypt')}>Egypt</p>
                    <p onClick={() => setCurrentBg('honey')}>Honey</p>
                    <p onClick={() => setCurrentBg('redbrick')}>Red Brick</p>
                    <p onClick={() => setCurrentBg('rivets')}>Rivets</p>
                    <p onClick={() => setCurrentBg('squares')}>Squares</p>
                    <p onClick={() => setCurrentBg('thatch')}>Thatch</p>
                    <p onClick={() => setCurrentBg('zigzag')}>Zig Zag</p>
                  </div>
                </div>
                <button
                  className="button separate"
                  id="wallpaper-btn"
                  onClick={() => setShowWallpaperList(!showWallpaperList)}
                >
                  <i className="arrow-down" />
                </button>
              </div>
            </fieldset>
            <fieldset>
              <legend>Icons</legend>
            </fieldset>
          </div>
        </div>
      </div>
      <div
        className={`context-menu ${
          contextMenu ? 'context-menu--active' : 'context-menu--disabled'
        }`}
      >
        {!props.fullScreen && (
          <div
            className="context-item"
            onClick={() => !fullScreen && _startDrag()}
          >
            <span style={{ textDecoration: 'underline' }}>M</span>ove
          </div>
        )}
        <div className="context-item" onClick={() => props.show(false)}>
          Mi<span style={{ textDecoration: 'underline' }}>n</span>imize
        </div>
        <div className="context-item" onClick={() => props.show(false)}>
          <span style={{ textDecoration: 'underline' }}>C</span>lose
        </div>
      </div>
    </motion.div>
  )
}
