import React from 'react'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import Terminal from 'terminal-in-react'

import AboutMe from './AboutMe'

export default props => {
  const titleBar = React.useRef(null)
  const iconsContainer = React.useRef(null)
  const windowBorder = React.useRef(null)

  const [contextMenu, setContextMenu] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [fullScreen, setFullScreen] = React.useState(false)
  const [startDrag, setStartDrag] = React.useState(false)

  React.useEffect(() => {
    props.constraintsRef.current.clientWidth >= 768 &&
      titleBar.current.addEventListener('mouseenter', e => {
        setStartDrag(true)
      })

    iconsContainer.current.addEventListener('mouseenter', e => {
      _stopDrag()
    })
  })

  function _startDrag() {
    setDragging(true)
    setStartDrag(true)
    setContextMenu(false)
  }

  function _stopDrag() {
    setDragging(false)
    setStartDrag(false)
  }

  return (
    <motion.div
      className={`window-border ${dragging ? 'ui-draggable-dragging' : ''}
      ${fullScreen ? 'maximize' : 'open'}`}
      drag={
        !fullScreen && props.constraintsRef.current.clientWidth >= 768
          ? startDrag
          : false
      }
      dragConstraints={props.constraintsRef}
      dragMomentum={false}
      onDragStart={_startDrag}
      onDragEnd={_stopDrag}
      ref={windowBorder}
    >
      <div
        className="title"
        onMouseUp={_stopDrag}
        ref={titleBar}
        onDoubleClick={() => (fullScreen ? setFullScreen(false) : {})}
      >
        <div
          id="window"
          className={`button close ${
            contextMenu ? 'context-menu--active' : 'context-menu--disabled'
          }`}
          onClick={() => setContextMenu(!contextMenu)}
          onDoubleClick={() => props.show(false)}
        >
          &#8212;
        </div>
        MS-DOS Prompt
        <div className="button" id="max" onClick={() => setFullScreen(true)}>
          <i className="caret-up" />
        </div>
        <div
          className="button"
          id="min"
          onClick={() =>
            fullScreen ? setFullScreen(false) : props.show(false)
          }
        >
          <i className="caret-down" />
        </div>
      </div>
      <div className="window terminal">
        <div className="menubar">
          <div className="menuitem">
            <span style={{ textDecoration: 'underline' }}>H</span>elp
          </div>
        </div>
        <div ref={iconsContainer} className="terminal-container">
          <Terminal
            allowTabs={false}
            color="black"
            backgroundColor="white"
            startState="maximised"
            hideTopBar
            style={{
              fontSize: '1em',
              fontFamily: 'fixedsys, consolas, monospace',
            }}
            commands={{
              'about-me': () => <AboutMe />,
              exit: () => props.show(false),
            }}
            descriptions={{
              echo: false,
              'edit-line': false,
              help: 'list all the available commands',
              'about-me': 'information about me',
              exit: 'to close the terminal',
            }}
            prompt="black"
            promptSymbol="C>"
            msg="Welcome to my site. Start by typing: 'help', to see the available commands."
            watchConsoleLogging={false}
            shortcuts={{
              'win,linux': {
                'ctrl + l': 'clear',
              },
              darwin: {
                'cmd + l': 'clear',
              },
            }}
          />
        </div>
      </div>
      <div
        className={`context-menu ${
          contextMenu ? 'context-menu--active' : 'context-menu--disabled'
        }`}
      >
        <div className="context-item" onClick={() => _startDrag()}>
          <span style={{ textDecoration: 'underline' }}>M</span>ove
        </div>
        <div className="context-item" onClick={() => props.show(false)}>
          Mi<span style={{ textDecoration: 'underline' }}>n</span>imize
        </div>
        <div className="context-item" onClick={() => setFullScreen(true)}>
          Ma<span style={{ textDecoration: 'underline' }}>x</span>imize
        </div>
      </div>
    </motion.div>
  )
}

export const dataQueries = graphql`
  {
    ExperienceQuery: allSanityExperience {
      edges {
        node {
          id
          title
          years
          sort
          _rawBody
        }
      }
    }
  }
`
