import React from 'react'
import * as THREE from 'three'

// const OrbitControls = require('three-orbit-controls')(THREE)

export default () => {
  let CanvasWrap = React.useRef(),
    camera,
    geometry,
    height,
    fullColor,
    red,
    renderer,
    scene,
    width,
    windowHalfX,
    windowHalfY,
    winLogos

  // useEffect
  React.useEffect(() => {
    fullColor = new THREE.TextureLoader().load(
      require('../assets/img/win-logo-small.png')
    )
    red = new THREE.TextureLoader().load(
      require('../assets/img/win-logo-red.png')
    )

    width = CanvasWrap.current.clientWidth
    height = CanvasWrap.current.clientHeight
    windowHalfX = width / 2
    windowHalfY = height / 2

    createScene()
    createCamera()
    createGeometry()
    // createGeometryColor(blue)
    createGeometryColor(red)
    // createGeometryColor(white)
    // createGeometryColor(yellow)

    animate()

    typeof window !== 'undefined' && window.addEventListener('resize', onResize)

    return () => {
      scene.remove()
      geometry.dispose()
      fullColor.dispose()
      red.dispose()
    }
  })

  function animate() {
    // if (controls) controls.update()

    geometry.vertices.forEach(p => {
      p.velocity += p.acceleration

      p.y -= p.velocity

      if (p.y < -200) {
        p.y = 200

        p.velocity = 0
      }
    })

    geometry.verticesNeedUpdate = true

    winLogos.rotation.y += 0.002

    render()

    requestAnimationFrame(animate)
  }

  function createScene() {
    scene = new THREE.Scene()

    renderer = new THREE.WebGLRenderer()
    renderer.setSize(width, height)

    CanvasWrap.current.appendChild(renderer.domElement)
  }

  function createCamera() {
    let aspectRatio = width / height,
      farPlane = 1000,
      fieldOfView = 40,
      nearPlane = 1

    camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearPlane,
      farPlane
    )

    camera.rotation.x = Math.PI / 2
    camera.position.z = 1
  }

  function createGeometry() {
    geometry = new THREE.Geometry()

    for (var i = 0; i < 2500; i++) {
      let logo = new THREE.Vector3(
        Math.random() * 500 - 200,
        Math.random() * 500 - 200,
        Math.random() * 500 - 200
      )
      logo.velocity = 0
      logo.acceleration = 0.02
      geometry.vertices.push(logo)
    }

    let spriteMaterial = new THREE.PointsMaterial({
      size: 5,
      map: fullColor,
      transparent: true,
    })

    winLogos = new THREE.Points(geometry, spriteMaterial)
    scene.add(winLogos)
  }
  function createGeometryColor(color) {
    let spriteMaterial = new THREE.PointsMaterial({
      size: 5,
      map: color,
      transparent: true,
    })

    winLogos = new THREE.Points(geometry, spriteMaterial)
    scene.add(winLogos)
  }

  function onResize() {
    camera.aspect = width / height
    camera.updateProjectionMatrix()
    renderer.setSize(width, height)
  }

  function render() {
    renderer.render(scene, camera)
  }

  return <div className="window-canvas" ref={CanvasWrap} />
}
