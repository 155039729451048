import React from 'react'
import { motion } from 'framer-motion'

import Clock from '../components/Clock'

export default props => {
  const titleBar = React.useRef(null)
  const iconsContainer = React.useRef(null)
  const windowBorder = React.useRef(null)

  const [analog, setAnalog] = React.useState(true)
  const [contextMenu, setContextMenu] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [dateEnabled, enableDate] = React.useState(true)
  const [fullScreen, setFullScreen] = React.useState(false)
  const [titleEnabled, enableTitle] = React.useState(true)
  const [startDrag, setStartDrag] = React.useState(false)
  const [settingsActive, setSettingsActive] = React.useState(false)

  React.useEffect(() => {
    props.constraintsRef.current.clientWidth >= 768 &&
      titleBar.current.addEventListener('mouseenter', e => {
        setStartDrag(true)
      })

    iconsContainer.current.addEventListener('mouseenter', e => {
      _stopDrag()
    })

    // making the context menu work
    if (contextMenu) {
      typeof document !== 'undefined' &&
        document.addEventListener('keydown', function(event) {
          if (event.code === 'KeyC') {
            props.show(false)
          }
          if (event.code === 'KeyM') {
            _startDrag()
          }
        })
    }
  })

  function _startDrag() {
    setDragging(true)
    setStartDrag(true)
    setContextMenu(false)
  }

  function _stopDrag() {
    setDragging(false)
    setStartDrag(false)
  }

  return (
    <motion.div
      className={`window-border clock ${dragging ? 'ui-draggable-dragging' : ''}
      ${fullScreen ? 'maximize' : 'open'}`}
      drag={
        !fullScreen && props.constraintsRef.current.clientWidth >= 768
          ? startDrag
          : false
      }
      dragConstraints={props.constraintsRef}
      dragMomentum={false}
      onDragStart={_startDrag}
      onDragEnd={_stopDrag}
      ref={windowBorder}
    >
      <div
        className="title"
        onMouseUp={_stopDrag}
        ref={titleBar}
        onDoubleClick={() => (fullScreen ? setFullScreen(false) : {})}
      >
        <div
          id="window"
          className={`button close ${
            contextMenu ? 'context-menu--active' : 'context-menu--disabled'
          }`}
          onClick={() => setContextMenu(!contextMenu)}
          onDoubleClick={() => props.show(false)}
        >
          &#8212;
        </div>
        {titleEnabled && props.windowTitle}
        {props.subTitle && dateEnabled && ` - ${props.date}`}
        <div className="button" id="max" onClick={() => setFullScreen(true)}>
          <i className="caret-up" />
        </div>
        <div
          className="button"
          id="min"
          onClick={() =>
            fullScreen ? setFullScreen(false) : props.show(false)
          }
        >
          <i className="caret-down" />
        </div>
      </div>
      <div className={`window ${props.id}`}>
        <div className="menubar">
          <div
            className="menuitem"
            onClick={() => setSettingsActive(!settingsActive)}
          >
            Settings
            <div
              className={`menuitem__dropdown ${settingsActive ? 'active' : ''}`}
            >
              <ul>
                <li
                  className="menuitem__dropdown__item"
                  onClick={() => setAnalog(!analog)}
                >
                  <i className={analog ? 'check' : 'empty'} />
                  Analog
                </li>
                <li
                  className="menuitem__dropdown__item"
                  onClick={() => setAnalog(!analog)}
                >
                  <i className={analog ? 'empty' : 'check'} />
                  Digital
                </li>
                <li className="menuitem__dropdown__item disabled">
                  <i className="empty" />
                  Set Font...
                </li>
                <li
                  className="menuitem__dropdown__item"
                  onClick={() => enableTitle(!titleEnabled)}
                >
                  <i className={titleEnabled ? 'check' : 'no-date'} />
                  No Title
                </li>
                <li className="menuitem__dropdown__item disabled">
                  <i className="check" />
                  Seconds
                </li>
                <li
                  className="menuitem__dropdown__item"
                  onClick={() => enableDate(!dateEnabled)}
                >
                  <i className={dateEnabled ? 'check' : 'no-date'} />
                  Date
                </li>
                <li className="menuitem__dropdown__item disabled">
                  <i className="na" />
                  About Clock...
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          ref={iconsContainer}
          className={`${props.id}`}
          onClick={() => settingsActive && setSettingsActive(!settingsActive)}
        >
          <Clock analog={analog} date={props.date} />
        </div>
      </div>
      <div
        className={`context-menu ${
          contextMenu ? 'context-menu--active' : 'context-menu--disabled'
        }`}
      >
        <div className="context-item" onClick={() => _startDrag()}>
          <span style={{ textDecoration: 'underline' }}>M</span>ove
        </div>
        <div className="context-item" onClick={() => props.show(false)}>
          Mi<span style={{ textDecoration: 'underline' }}>n</span>imize
        </div>
        <div className="context-item" onClick={() => setFullScreen(true)}>
          Ma<span style={{ textDecoration: 'underline' }}>x</span>imize
        </div>
        <div className="context-item" onClick={() => props.show(false)}>
          <span style={{ textDecoration: 'underline' }}>C</span>lose
        </div>
      </div>
    </motion.div>
  )
}
