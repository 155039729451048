import React from 'react'
import { motion } from 'framer-motion'
import Swal from 'sweetalert2'

import { notNilOrEmpty } from '../lib/Helpers'

export default props => {
  const titleBar = React.useRef(null)
  const iconsContainer = React.useRef(null)
  const windowBorder = React.useRef(null)

  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    message: ''
  })
  const [contextMenu, setContextMenu] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [fullScreen, setFullScreen] = React.useState(false)
  const [startDrag, setStartDrag] = React.useState(false)

  React.useEffect(() => {
    props.constraintsRef.current.clientWidth >= 768 &&
      titleBar.current.addEventListener('mouseenter', e => {
        setStartDrag(true)
      })

    iconsContainer.current.addEventListener('mouseenter', e => {
      _stopDrag()
    })

    // making the context menu work
    if (contextMenu) {
      typeof document !== 'undefined' &&
        document.addEventListener('keydown', function(event) {
          if (event.code === 'KeyC') {
            props.show(false)
          }
          if (event.code === 'KeyM') {
            _startDrag()
          }
        })
    }
  })

  function _startDrag() {
    setDragging(true)
    setStartDrag(true)
    setContextMenu(false)
  }

  function _stopDrag() {
    setDragging(false)
    setStartDrag(false)
  }

  function _handleInputChange(e) {
    e.preventDefault()
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  function _handleForm(e) {
    e.preventDefault()

    fetch('/api/contactForm', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message
      })
    }).then(res => {
      if (res.status >= 400) {
        console.error('there was an error', res)
        return Swal.fire({
          icon: 'error',
          title: 'Oops... Something went wrong!',
          text: res.statusTest,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        })
      } else {
        setFormData({
          name: '',
          email: '',
          message: ''
        })

        return Swal.fire({
          icon: 'success',
          title: 'Thank you for your message.',
          text: 'I will be in touch soon.',
          customClass: {
            confirmButton: 'primary'
          },
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        })
      }
    })
  }

  return (
    <motion.div
      className={`window-border ${props.id} ${
        dragging ? 'ui-draggable-dragging' : ''
      }
      ${props.fullScreen ? 'maximize' : 'open'}`}
      drag={
        !props.fullScreen && props.constraintsRef.current.clientWidth >= 768
          ? startDrag
          : false
      }
      dragConstraints={props.constraintsRef}
      dragMomentum={false}
      onDragStart={_startDrag}
      onDragEnd={_stopDrag}
      ref={windowBorder}
    >
      <div className="title" onMouseUp={_stopDrag} ref={titleBar}>
        <div
          id="window"
          className={`button close ${
            contextMenu ? 'context-menu--active' : 'context-menu--disabled'
          }`}
          onClick={() => setContextMenu(!contextMenu)}
          onDoubleClick={() => props.show(false)}
        >
          &#8212;
        </div>
        {props.windowTitle} {props.subTitleText && ` - ${props.subTitleText}`}
        <div className="button" id="min" onClick={() => props.show(false)}>
          <i className="caret-down" />
        </div>
      </div>
      <div className={`window ${props.id}`}>
        {props.menubar && (
          <div className="menubar">
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>F</span>ile
            </div>
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>O</span>ptions
            </div>
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>W</span>indows
            </div>
            <div className="menuitem">
              <span style={{ textDecoration: 'underline' }}>H</span>elp
            </div>
          </div>
        )}
        <div ref={iconsContainer} className={`${props.id}__inner`}>
          <form method="post" action="/" onSubmit={_handleForm}>
            <div className="split-half">
              <fieldset>
                <legend>Your Name</legend>
                <input
                  className="input"
                  type="text"
                  name="name"
                  id="name"
                  onChange={_handleInputChange}
                  value={formData.name}
                />
              </fieldset>
              <fieldset>
                <legend>Your Email</legend>
                <input
                  className="input"
                  type="email"
                  name="email"
                  id="email"
                  onChange={_handleInputChange}
                  value={formData.email}
                />
              </fieldset>
            </div>
            <div className="message">
              <fieldset>
                <legend>Message</legend>
                <textarea
                  className="textarea"
                  name="message"
                  id="message"
                  onChange={_handleInputChange}
                  value={formData.message}
                />
              </fieldset>
            </div>
            <div className="submit-button">
              <button
                className="button btn "
                type="submit"
                disabled={
                  notNilOrEmpty(formData.name) &&
                  notNilOrEmpty(formData.email) &&
                  notNilOrEmpty(formData.message)
                    ? false
                    : true
                }
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`context-menu ${
          contextMenu ? 'context-menu--active' : 'context-menu--disabled'
        }`}
      >
        {!props.fullScreen && (
          <div
            className="context-item"
            onClick={() => !fullScreen && _startDrag()}
          >
            <span style={{ textDecoration: 'underline' }}>M</span>ove
          </div>
        )}
        <div className="context-item" onClick={() => props.show(false)}>
          Mi<span style={{ textDecoration: 'underline' }}>n</span>imize
        </div>
        {!props.fullScreen && (
          <div className="context-item">
            Ma<span style={{ textDecoration: 'underline' }}>x</span>imize
          </div>
        )}
        <div className="context-item" onClick={() => props.show(false)}>
          <span style={{ textDecoration: 'underline' }}>C</span>lose
        </div>
      </div>
    </motion.div>
  )
}
